import { defineComponent as _defineComponent } from 'vue'
import { toRefs } from "vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import { useRouter } from "vue-router";
import BaseBorderLoader from "@/components/BaseBorderLoader.vue";
import { RENEWABLE_ENERGY_TARGET_MANAGEMENT } from "@ems/constants";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { IScopeTwoDataEmission } from "@/models/ScopeTwoEmissions";
import { ITargetRenewableTargetDTO } from "@/models/RenewableEnergyTarget";

interface Props {
  dataTargetGetRenewableTarget: ITargetRenewableTargetDTO;
  dataScopeTwoTargetPercent: number | string;
  isLoadingTarget: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TargetBar',
  props: {
    dataTargetGetRenewableTarget: {},
    dataScopeTwoTargetPercent: {},
    isLoadingTarget: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const {
  dataTargetGetRenewableTarget,
  dataScopeTwoTargetPercent,
  isLoadingTarget,
} = toRefs(props);

const router = useRouter();

const __returned__ = { props, dataTargetGetRenewableTarget, dataScopeTwoTargetPercent, isLoadingTarget, router, toRefs, BaseSvgIcon, get useRouter() { return useRouter }, BaseBorderLoader, get RENEWABLE_ENERGY_TARGET_MANAGEMENT() { return RENEWABLE_ENERGY_TARGET_MANAGEMENT }, BaseSpinner, get IScopeTwoDataEmission() { return IScopeTwoDataEmission }, get ITargetRenewableTargetDTO() { return ITargetRenewableTargetDTO } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})