import { defineComponent as _defineComponent } from 'vue'
import BaseBorderLoader from "@/components/BaseBorderLoader.vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
import DashBoardModule from "@ems/containers/Dashboard/Dashboard.module";
import ScopeOneAndThreeEmissionModule from "@ems/containers/ScopeOneAndThreeEmission/ScopeOneAndThreeEmission.module";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
interface Props {
  key: any;
  isLoadingScopeTwoEmissionChart: boolean;
}
const percentage = 100;

export default /*@__PURE__*/_defineComponent({
  __name: 'CarbonEmissionsByScopeBar',
  props: {
    key: {},
    isLoadingScopeTwoEmissionChart: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { isLoadingScopeTwoEmissionChart } = toRefs(props);
const currentYear = computed<any>(() => DashBoardModule.currentYearGetter);
const dataScopeOne = computed<any>(
  () => ScopeOneAndThreeEmissionModule.dataScopeOneBaseGetter
);
const dataScopeThree = computed<any>(
  () => ScopeOneAndThreeEmissionModule.dataScopeThreeBaseGetter
);
const dataScopeTwoEmission = computed<any>(
  () => ScopeTwoEmissionModule.dataScopeTwoEmissionBaseGetter
);
const isLoadingScopeOneAndThree = computed(
  () => ScopeOneAndThreeEmissionModule.loadingScopeOneAndThreeGetter
);
const renderDataByYear = (conditionElse: any) => {
  return currentYear.value >= new Date().getFullYear()
    ? "N/A"
    : conditionElse && typeof conditionElse === "number"
    ? formatNumber(conditionElse, 0)
    : conditionElse;
};
const parseDataPercent = (dataCurrent: number, dataPrevious: number) => {
  const dataScope = {
    dataCurrent: dataCurrent && dataCurrent.toFixed(0),
    dataPrevious: dataPrevious && dataPrevious.toFixed(0),
  } as { dataCurrent: number; dataPrevious: number };
  if (Math.abs(dataScope.dataPrevious) === 0) {
    return "N/A";
  }
  const result =
    ((dataScope.dataCurrent - dataScope.dataPrevious) /
      dataScope.dataPrevious) *
    percentage;
  return result.toFixed(0);
};

const parseUIRender = (
  dataCurrent: number,
  dataPrevious: number,
  isCurrent: boolean
) => {
  const result = ((dataCurrent - dataPrevious) / dataPrevious) * percentage > 0;
  return result || dataPrevious === 0 || isCurrent;
};
const router = useRouter();

const __returned__ = { props, isLoadingScopeTwoEmissionChart, percentage, currentYear, dataScopeOne, dataScopeThree, dataScopeTwoEmission, isLoadingScopeOneAndThree, renderDataByYear, parseDataPercent, parseUIRender, router, BaseBorderLoader, BaseSvgIcon, computed, toRefs, get useRouter() { return useRouter }, get DashBoardModule() { return DashBoardModule }, get ScopeOneAndThreeEmissionModule() { return ScopeOneAndThreeEmissionModule }, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get formatNumber() { return formatNumber } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})