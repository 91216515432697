import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "box h-28 2xl:h-30 w-3/2 relative overflow-hidden" }
const _hoisted_2 = { class: "flex items-center h-full space-x-7" }
const _hoisted_3 = { class: "flex flex-col py-3 space-y-1 2xl:space-y-3" }
const _hoisted_4 = { class: "text-gray-400" }
const _hoisted_5 = { class: "text-sm 2xl:text-lg" }
const _hoisted_6 = { class: "text-sm 2xl:text-lg" }
const _hoisted_7 = ["stroke-dasharray"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isLoadingTarget)
      ? (_openBlock(), _createBlock($setup["BaseSpinner"], { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "absolute top-2 right-5",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.router.push($setup.RENEWABLE_ENERGY_TARGET_MANAGEMENT.path)), ["prevent"]))
      }, [
        _createVNode($setup["BaseSvgIcon"], {
          class: "inline-block w-8 h-8",
          name: "3-dots"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString($setup.dataTargetGetRenewableTarget?.Standard ||
            "Assign an Active Target to Dashboard"), 1),
          _createElementVNode("p", _hoisted_5, [
            _createTextVNode(_toDisplayString($setup.dataTargetGetRenewableTarget?.TargetYear || "N/A") + ": ", 1),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.styles['text-green-bold'])
            }, _toDisplayString($setup.dataTargetGetRenewableTarget?.TargetValue
              ? `${$setup.dataTargetGetRenewableTarget?.TargetValue}% Renewable`
              : "N/A"), 3)
          ]),
          _createElementVNode("p", _hoisted_6, [
            _cache[1] || (_cache[1] = _createTextVNode(" Current: ")),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.styles['text-green'])
            }, _toDisplayString($setup.dataTargetGetRenewableTarget
              ? `${$setup.dataScopeTwoTargetPercent}% Renewable`
              : "N/A"), 3)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.styles['single-chart'])
        }, [
          (_openBlock(), _createElementBlock("svg", {
            viewBox: "0 0 36 36",
            class: _normalizeClass([_ctx.styles['circular-chart'], _ctx.styles.green])
          }, [
            _createElementVNode("path", {
              class: _normalizeClass(_ctx.styles['circle-bg']),
              d: "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
            }, null, 2),
            _createElementVNode("path", {
              class: _normalizeClass(_ctx.styles.circle),
              "stroke-dasharray": `${$setup.dataScopeTwoTargetPercent}, 100`,
              d: "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
            }, null, 10, _hoisted_7),
            _createElementVNode("text", {
              x: "18",
              y: "20.35",
              class: _normalizeClass(_ctx.styles.percentage)
            }, _toDisplayString($setup.dataScopeTwoTargetPercent) + "% ", 3)
          ], 2))
        ], 2)
      ]),
      _createVNode($setup["BaseBorderLoader"], {
        isLoading: true,
        rounded: "20"
      })
    ])
  ], 64))
}