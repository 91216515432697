import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 gap-5 h-full" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex flex-col space-y-2 -ml-3" }
const _hoisted_4 = { class: "2xl:text-2xl" }
const _hoisted_5 = { class: "flex flex-col items-center 2xl:ml-2 space-y-2" }
const _hoisted_6 = { class: "text-xs 2xl:text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'box',
      'h-28',
      ' 2xl:h-30',
      'w-full',
      'relative',
      'overflow-hidden',
      $setup.isLoadingScopeOneAndThree ? 'loading-skeleton' : '',
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-gray-400 text-lg 2xl:text-xl text-center transform -rotate-90 border-b border-gray-400 2xl:h-8 -ml-8 whitespace-nowrap" }, " SCOPE 2 ", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("strong", _hoisted_4, _toDisplayString($setup.dataScopeTwoEmission[$setup.currentYear] &&
            $setup.formatNumber($setup.dataScopeTwoEmission[$setup.currentYear], 0) === "-0"
              ? 0
              : $setup.formatNumber($setup.dataScopeTwoEmission[$setup.currentYear], 0)), 1),
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-sm 2xl:text-base text-gray-400" }, "MWh", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-gray-400" }, [
            _createElementVNode("span", { class: "visible 2xl:text-2xl" }, " "),
            _createElementVNode("span", { class: "text-xs 2xl:text-sm" }, "YoY")
          ], -1)),
          _createElementVNode("p", {
            class: _normalizeClass([
              'flex items-center',
              $setup.parseUIRender(
                $setup.dataScopeTwoEmission[$setup.currentYear],
                $setup.dataScopeTwoEmission[$setup.currentYear - 1],
                $setup.currentYear > new Date().getFullYear()
              )
                ? _ctx.styles.increment
                : _ctx.styles.decrement,
            ])
          }, [
            (
                $setup.parseUIRender(
                  $setup.dataScopeTwoEmission[$setup.currentYear],
                  $setup.dataScopeTwoEmission[$setup.currentYear - 1],
                  $setup.currentYear > new Date().getFullYear()
                )
              )
              ? (_openBlock(), _createBlock($setup["BaseSvgIcon"], {
                  key: 0,
                  class: "inline-block w-5 h-5",
                  name: "top"
                }))
              : (_openBlock(), _createBlock($setup["BaseSvgIcon"], {
                  key: 1,
                  class: "inline-block w-5 h-5",
                  name: "drop-down"
                })),
            _createElementVNode("span", _hoisted_6, _toDisplayString($setup.parseDataPercent(
                  $setup.dataScopeTwoEmission[$setup.currentYear],
                  $setup.dataScopeTwoEmission[$setup.currentYear - 1]
                )) + "%", 1)
          ], 2)
        ])
      ])
    ]),
    _createVNode($setup["BaseBorderLoader"], {
      isLoading: $setup.isLoadingScopeTwoEmissionChart,
      rounded: "20"
    }, null, 8, ["isLoading"])
  ], 2))
}