import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "px-10 pt-2 pb-8" }
const _hoisted_2 = { className: "grid grid-cols-1 2xl:gap-4 gap-2 2xl:py-4 py-3" }
const _hoisted_3 = { class: "flex flex-wrap items-center" }
const _hoisted_4 = {
  class: "box h-28 w-16 xl:order-0 2xl:h-30 2xl:w-20 2xl:mr-5 lg:order-0 mr-3 mt-3",
  "data-aos": "fade-down",
  "data-aos-easing": "ease-out",
  "data-aos-duration": "750"
}
const _hoisted_5 = { class: "flex flex-col justify-between items-center h-full p-2" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  class: "xl:w-1/6 lg:w-full lg:order-1 2xl:mr-5 mt-3 mr-3 xl:order-0",
  "data-aos": "fade-down"
}
const _hoisted_8 = {
  class: "mt-3 lg:w-5/12 xl:w-1/3 2xl:w-1/4 lg:order-0 xl:order-1 2xl:mr-5 mr-3",
  "data-aos": "fade-down",
  "data-aos-duration": "500",
  "data-aos-easing": "ease-out"
}
const _hoisted_9 = { class: "grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 2xl:gap-4 gap-2" }
const _hoisted_10 = {
  class: "box 2xl:h-90 h-72 w-full bg-white relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "600"
}
const _hoisted_11 = { class: "relative" }
const _hoisted_12 = {
  key: 1,
  class: "inputfield line-clamp-1 absolute bottom-6"
}
const _hoisted_13 = {
  class: "box 2xl:h-90 h-72 w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "900"
}
const _hoisted_14 = { class: "flex justify-between items-center mt-1" }
const _hoisted_15 = { class: "text-xs 2xl:text-sm" }
const _hoisted_16 = { class: "mt-5" }
const _hoisted_17 = {
  class: "box 2xl:h-90 h-72 w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "900"
}
const _hoisted_18 = { class: "mt-5" }
const _hoisted_19 = {
  class: "box 2xl:h-90 h-72 w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "750"
}
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = {
  class: "box 2xl:h-90 h-72 w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "1200"
}
const _hoisted_22 = {
  class: "box 2xl:h-90 h-72 w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "1200"
}
const _hoisted_23 = { class: "flex justify-between" }
const _hoisted_24 = { class: "float-right my-3" }
const _hoisted_25 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "font-semibold text-gray-700 hover:text-blue-500",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.handleOpenModal(-1)), ["prevent"]))
            }, _toDisplayString(parseInt(`${$setup.currentYear}`) - 1), 1),
            _createElementVNode("button", {
              type: "button",
              class: "font-semibold 2xl:text-xl text-blue-500",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.handleOpenModal(0)), ["prevent"]))
            }, _toDisplayString($setup.currentYear), 1),
            _createElementVNode("button", {
              disabled: 
                parseInt(`${$setup.currentYear}`) + 1 > new Date().getFullYear()
              ,
              class: "font-semibold text-gray-700 hover:text-blue-500",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.handleOpenModal(1)), ["prevent"]))
            }, _toDisplayString(parseInt(`${$setup.currentYear}`) + 1), 9, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode($setup["CarbonEmissionsByScopeBar"], {
            isLoadingScopeTwoEmissionChart: $setup.isLoadingScopeTwoEmissionChart,
            key: "dataCarbonEmissionsSummary"
          }, null, 8, ["isLoadingScopeTwoEmissionChart"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode($setup["TargetBar"], {
            dataTargetGetRenewableTarget: $setup.dataTargetGetRenewableTarget,
            dataScopeTwoTargetPercent: $setup.dataScopeTwoTargetPercent,
            isLoadingTarget: $setup.isLoadingTargetGetRenewableTarget
          }, null, 8, ["dataTargetGetRenewableTarget", "dataScopeTwoTargetPercent", "isLoadingTarget"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          class: "absolute top-2 right-5",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.router.push('/performance-world-map')), ["prevent"]))
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "inline-block w-8 h-8",
            name: "3-dots"
          })
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl" }, " Renewable Performance World Map ", -1)),
        _createElementVNode("div", _hoisted_11, [
          (!$setup.isLoadingWordMapEmissionGetter)
            ? (_openBlock(), _createBlock($setup["Maps"], {
                key: 0,
                data: $setup.worldMapEmissionGetter.ScopeTwoEmissions,
                linearColor: $setup.scope,
                class: _normalizeClass(_ctx.styles['map-size'])
              }, null, 8, ["data", "class"]))
            : _createCommentVNode("", true),
          (!$setup.isLoadingWordMapEmissionGetter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[9] || (_cache[9] = [
                _createElementVNode("button", null, [
                  _createElementVNode("div", { class: "w-3 h-3 bg-cafe-gray rounded" })
                ], -1),
                _createElementVNode("label", {
                  for: "scope",
                  class: "cursor-pointer"
                }, " No Facility ", -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _createVNode($setup["BaseBorderLoader"], {
          isLoading: $setup.isLoadingWordMapEmissionGetter,
          rounded: "20"
        }, null, 8, ["isLoading"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          class: "absolute top-2 right-5",
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.router.push('/scope-2-renewables-performance')), ["prevent"]))
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "inline-block w-8 h-8",
            name: "3-dots"
          })
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl" }, " Scope 2 Renewables Performance ", -1)),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", null, [
              _createTextVNode(" Global / " + _toDisplayString($setup.currentYear) + " RE Performance ", 1),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.styles.unit)
              }, _toDisplayString($setup.dataScopeTwoTargetPercent) + "%", 3)
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn--green-primary",
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => ($setup.router.push('/renewable-options/buy-recs')), ["prevent"]))
            }, " Buy RECs ")
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          (!$setup.isLoadingScopeTwoEnergyLoad)
            ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                key: 0,
                class: _normalizeClass(_ctx.styles['column-size']),
                data: $setup.dataScopeTwoEnergyLoad,
                colors: ['#808080', '#808080'],
                data_2: $setup.dataScopeTwoRenewableEnergy,
                colors_2: ['#8EC63F', '#8EC63F'],
                seriesName: "Scope 2",
                seriesName_2: "Renewables",
                lineSeriesName: "Renewables (%) ",
                lineData: $setup.dataScopeTwoLineMarker,
                lineMarker: $setup.dataScopeTwoEmissionTarget,
                percentChart: $setup.dataMaxPercentChart,
                lineMarkerName: "Target (%)",
                yTitle: "kWh",
                legendStyle: {
              position: 'bottom',
              fontSize: 12,
              padding: [15, 0, 0, 0],
            },
                withExtraYScale: true,
                lineAllowExtraYScale: true
              }, null, 8, ["class", "data", "data_2", "lineData", "lineMarker", "percentChart"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode($setup["BaseBorderLoader"], {
          isLoading: $setup.isLoadingScopeTwoEnergyLoad,
          rounded: "20"
        }, null, 8, ["isLoading"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("button", {
          class: "absolute top-2 right-5",
          onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => ($setup.router.push('/supply-chain-management')), ["prevent"]))
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "inline-block w-8 h-8",
            name: "3-dots"
          })
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "mt-2 mr-6 text-left text-lg 2xl:text-xl" }, " Supply Chain's Renewable Performance Distribution ", -1)),
        _createElementVNode("div", _hoisted_18, [
          (!$setup.isLoadingGetBarCharts)
            ? (_openBlock(), _createBlock($setup["BarChart"], {
                key: 0,
                class: _normalizeClass(_ctx.styles['column-size']),
                data: $setup.barChartData,
                colors: ['#176604', '#176604'],
                xTitle: "Renewable performance",
                yTitle: "No. of companies",
                barsPadding: 0.2,
                seriesName: "Companies"
              }, null, 8, ["class", "data"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode($setup["BaseBorderLoader"], {
          isLoading: $setup.isLoadingGetBarCharts,
          rounded: "20"
        }, null, 8, ["isLoading"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("button", {
          class: "absolute top-2 right-5",
          onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => ($setup.router.push('/renewable-options')), ["prevent"]))
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "inline-block w-8 h-8",
            name: "3-dots"
          })
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl" }, " Performance Overview (Global) ", -1)),
        _createElementVNode("div", _hoisted_20, [
          (!$setup.isLoadingPerformanceReview)
            ? (_openBlock(), _createBlock($setup["DoughnutChart"], {
                key: 0,
                class: _normalizeClass([
              $setup.dataScope2PerformanceReviewConsumed[0].x === 'No Data'
                ? _ctx.styles['container__chart']
                : _ctx.styles['column-size-map'],
            ]),
                title: "Renewable Percentage",
                data: $setup.dataScope2PerformanceReviewConsumed
              }, null, 8, ["class", "data"]))
            : _createCommentVNode("", true),
          (!$setup.isLoadingPerformanceReview)
            ? (_openBlock(), _createBlock($setup["DoughnutChart"], {
                key: 1,
                title: "Sources of Renewables",
                data: $setup.dataScope2PerformanceReviewRenewable
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode($setup["BaseBorderLoader"], {
          isLoading: $setup.isLoadingPerformanceReview,
          rounded: "20"
        }, null, 8, ["isLoading"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _cache[14] || (_cache[14] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl" }, " Facilities Monitoring ", -1)),
        _createVNode($setup["GoogleMap"], {
          data: $setup.dataGetListLocationFacility,
          dataCenterMap: $setup.center,
          classMap: _ctx.styles['map-size'],
          contentMarker: $setup.TypeContentMarker.CONTENT_FACILITY_RENEWABLE,
          mapTypeId: $setup.TypeMap.ROADMAP,
          markerWhite: false
        }, null, 8, ["data", "dataCenterMap", "classMap", "contentMarker", "mapTypeId"]),
        _createVNode($setup["BaseBorderLoader"], {
          isLoading: $setup.isLoadingScopeTwoEnergyLoad,
          rounded: "20"
        }, null, 8, ["isLoading"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("button", {
          class: "absolute top-2 right-5",
          onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => ($setup.router.push('/supply-chain-management')), ["prevent"]))
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "inline-block w-8 h-8",
            name: "3-dots"
          })
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl" }, " Supply Chain's Performance Trend Overview ", -1)),
        _createElementVNode("div", _hoisted_23, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "bg-green-100 w-fit h-full px-3 my-3" }, "AVG IN ABSOLUTE", -1)),
          _createElementVNode("p", _hoisted_24, _toDisplayString($setup.convertRangeMonthTilNow(5)), 1)
        ]),
        _createElementVNode("div", _hoisted_25, [
          (!$setup.isLoadingGetLineChart)
            ? (_openBlock(), _createBlock($setup["LineChart"], {
                key: 0,
                class: _normalizeClass(_ctx.styles['column-size']),
                data: $setup.lineChartData,
                legendStyle: {
              position: 'bottom',
              fontSize: 12,
              padding: [15, 0, 0, 0],
            },
                lineMarker: "circle",
                colors: ['#17A46D', '#17A46D'],
                seriesName: "Suppliers performance"
              }, null, 8, ["class", "data"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode($setup["BaseBorderLoader"], {
          isLoading: $setup.isLoadingGetLineChart,
          rounded: "20"
        }, null, 8, ["isLoading"])
      ])
    ])
  ]))
}